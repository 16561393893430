import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";

import ImageCard from "components/ImageCard";

const Top = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { header, subheader, imageFileName, urlFirst, urlFirstText, urlSecond, urlSecondText } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollToSection = useSmoothScrollTo(urlSecond);

  let extraInfoPart1;
  if (urlFirst && urlFirstText) {
    extraInfoPart1 = (
        <Button size="xl" variant="primary" className="text-uppercase" onClick={scrollToSection}>
          {urlFirstText}
        </Button>
    );
  }

  let extraInfoPart2;
  if (urlSecond && urlSecondText) {
    extraInfoPart2 = (
        <Button size="xl" variant="secondary" className="text-uppercase" onClick={scrollToSection}>
          {urlSecondText}
        </Button>
    );
  }

  return (
    <div id="home" className="">
      <ImageCard
        imageFileName={imageFileName}
        header={header}
        subheader={subheader}
        extraInfo1={extraInfoPart1}
        extraInfo2={extraInfoPart2}
      />
    </div>
  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
};

Top.defaultProps = {
  frontmatter: null,
};

export default Top;
